import React from "react"
import SEO from "../SEO"
import Layout from "../components/layout"
import Hero from "../components/hero"
import About from "../components/about"
import Work from "../components/work"
import Contact from "../components/contact"
import "./scss/index.scss"

export default () => (
  <>
    <SEO title="Robbie Galvin | Home" />
    <Layout>
      <Hero />
      <About />
      <Work />
      <Contact />
    </Layout>
  </>
)

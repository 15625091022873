import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import axios from "axios"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import styles from "./scss/contact.module.scss"
class Contact extends Component {
  state = {
    name: "",
    email: "",
    message: "",

    loading: false,
    info: "",
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.setState({ loading: true })
    const form = evt.target
    const axiosConfig = {
      header: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }

    axios
      .post(
        "/.netlify/functions/sendEmail",
        {
          name: this.state.name,
          email: this.state.email,
          message: this.state.message,
        },
        axiosConfig
      )
      .then(response => {
        if (response.status === 200) {
          this.setState({
            name: "",
            email: "",
            message: "",
            info: "Thank you for your message",
            loading: false,
          })
          form.reset()
          setTimeout(() => {
            this.setState({
              info: "",
            })
          }, 5000)
        }
      })
      .catch(() => {
        this.setState({
          info: "Opps, Something went wrong.. Please try again",
        })
        setTimeout(() => {
          this.setState({
            info: "",
          })
        }, 5000)
      })
  }

  handleChange = evt => {
    this.setState({
      [evt.target.name]: evt.target.value,
    })
  }

  render() {
    return (
      <section className={styles.contact} id="contact">
        <div className={styles.arrow}>
          <div className={styles.line}>&nbsp;</div>{" "}
        </div>
        <div className={styles.content}>
          <h2>{this.props.data.contentfulContact.title}</h2>

          {documentToReactComponents(
            this.props.data.contentfulContact.body.json
          )}

          <form onSubmit={this.handleSubmit}>
            <label htmlFor="name">Name</label>
            <input
              className={styles.input}
              type="text"
              id="name"
              name="name"
              onChange={this.handleChange}
            />
            <label htmlFor="email">Email</label>
            <input
              className={styles.input}
              type="text"
              id="email"
              name="email"
              onChange={this.handleChange}
            />
            <label htmlFor="message">Message</label>
            <textarea
              className={styles.input}
              type="text"
              id="message"
              rows="8"
              name="message"
              onChange={this.handleChange}
            />
            <input
              className={styles.input}
              type="submit"
              value={this.state.loading ? "Submitting..." : "Submit"}
            />
          </form>
          <span>{this.state.info}</span>
        </div>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        contentfulContact {
          title
          body {
            json
          }
        }
      }
    `}
    render={data => <Contact data={data} {...props} />}
  />
)

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styles from "./scss/hero.module.scss"

import { MdExpandMore } from "react-icons/md"
import Fade from "react-reveal/Fade"
import Reveal from "react-reveal/Reveal"

const hero = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHero {
          title
          subtitle
          image {
            title
            description
            fixed(width: 1597, height: 1066, quality: 50) {
              src
              srcSet
              srcSetWebp
            }
          }
        }
      }
    `}
    render={data => {
      const first = data.contentfulHero.title.split(" ")[0]
      const second = data.contentfulHero.title.split(" ")[1]
      return (
        <section className={styles.hero}>
          <div className={styles.content}>
            <h1>
              <Fade duration={2500} big>
                <span className={styles.first}>{first}</span>
              </Fade>

              <Fade delay={500} duration={2500} big>
                <span className={styles.second}>{second}</span>
              </Fade>
              <Fade delay={1000} duration={2500} big>
                <span className={styles.tagline}>
                  {data.contentfulHero.subtitle}
                </span>
              </Fade>
            </h1>
            <Fade delay={500} duration={2500} big>
              <img
                src={data.contentfulHero.image.fixed.src}
                alt={data.contentfulHero.image.description}
                title={data.contentfulHero.image.title}
              />
              {/* <img src={image} alt="" /> */}
            </Fade>
          </div>
          <Reveal duration={1500} effect={styles.bounce} forever={true}>
            <a href="#about" aria-label="Scroll Down">
              <MdExpandMore />
            </a>
          </Reveal>
        </section>
      )
    }}
  />
)

export default hero

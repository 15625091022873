import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import styles from "./scss/about.module.scss"
import { MdExpandMore } from "react-icons/md"
import Fade from "react-reveal/Fade"
const about = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulAbout {
          title
          body {
            json
          }
        }
      }
    `}
    render={data => (
      <section className={styles.about} id="about">
        <div className={styles.arrow}>
          <span>About</span> <div className={styles.line}>&nbsp;</div>{" "}
          <a href="#work">
            <MdExpandMore />
            Work
          </a>
        </div>
        <div className={styles.content}>
          <Fade duration={2500}>
            <h2>{data.contentfulAbout.title}</h2>
          </Fade>
          <Fade duration={2500} delay={500}>
            {documentToReactComponents(data.contentfulAbout.body.json)}
          </Fade>
        </div>
      </section>
    )}
  />
)

export default about

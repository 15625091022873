import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { MdChevronRight, MdExpandMore } from "react-icons/md"

import styles from "./scss/work.module.scss"
import Fade from "react-reveal/Fade"
const work = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulWork {
          title
          body {
            json
          }
        }
        allContentfulArticle(filter: { featured: { eq: true } }) {
          edges {
            node {
              title
              description {
                json
              }
              date
              link
              featured
            }
          }
        }
      }
    `}
    render={data => (
      <section className={styles.work} id="work">
        <div className={styles.arrow}>
          Work <div className={styles.line}>&nbsp;</div>
          <a href="#contact">
            <MdExpandMore />
            Contact
          </a>
        </div>
        <div className={styles.content}>
          <div className={styles.top}>
            <h2>{data.contentfulWork.title}</h2>
            {documentToReactComponents(data.contentfulWork.body.json)}
          </div>
          <div className={styles.bottom}>
            <ul>
              {data.allContentfulArticle.edges.map((edge, index) => (
                <Fade duration={2500} delay={index * 100} key={index}>
                  <li>
                    <a
                      href={edge.node.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h3>{edge.node.title}</h3>

                      {documentToReactComponents(edge.node.description.json)}
                    </a>
                  </li>
                </Fade>
              ))}

              <li>
                <Link to="/work">
                  <h4>All Work</h4> <MdChevronRight />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    )}
  />
)

export default work
